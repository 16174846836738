import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography'; 
import useMediaQuery from '@material-ui/core/useMediaQuery'; 
import Link from 'components/Link'; 

const customFonts = {
  'Grand Hotel': `<link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap" rel="stylesheet">`
}

const paramsObj = {
  'default': {
    text: process.env["NEXT_PUBLIC_COMPANY_NAME"],
    toLowerCase: true, 
    fontSize: 25,
    fontSizeXs: 22
  },
  'placestoelope.org': {
    text: process.env["NEXT_PUBLIC_COMPANY_NAME"],
    toLowerCase: false,
    customFont: 'Grand Hotel',
    fontSize: 32,
    fontSizeXs: 24, 
  },
  'allweddings.org': {
    text: process.env["NEXT_PUBLIC_COMPANY_NAME"],
    toLowerCase: false,
    customFont: 'Grand Hotel',
    fontSize: 32,
    fontSizeXs: 24, 
  },
  'weddingteam.org': {
    text: process.env["NEXT_PUBLIC_COMPANY_NAME"],
    toLowerCase: false,
    customFont: 'Grand Hotel',
    fontSize: 32,
    fontSizeXs: 24, 
  }
} 

const headerTitleA = {
  'default': {
    color: '#3880ff',
    fontWeight: 500,
    textDecoration: 'none',
  },
  'wedwedgo.com': {
    color: '#3880ff',
    fontWeight: 500,
    textDecoration: 'none',
  }
} 

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'flex-end',
    display: 'flex', 
    paddingBottom: 16 
  },
  headerTitle: {
    flexGrow: 1,
    fontSize: '25px',
    textRendering: 'optimizeLegibility',
    fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
    cursor: 'pointer'
  },
  headerTitleA: headerTitleA[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? headerTitleA[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : headerTitleA['default'],  
})); 

export default function Logo() {  
  const classes = useStyles();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

  let context = process.env["NEXT_PUBLIC_HOSTNAME"] || 'default';
  // console.log('context ', context)

  const params = typeof paramsObj[context] !== 'undefined' 
    ? paramsObj[context]
    : paramsObj['default']

  // console.log('params ', params)

  let text = process.env["NEXT_PUBLIC_COMPANY_NAME"];

  if (typeof params.toLowerCase !== 'undefined' && params.toLowerCase === true) {
    text = text.toLowerCase();
  } 

  let fontFamily = typeof params.customFont !== 'undefined'
      ? params.customFont
      : null

  // console.log('fontFamily ', fontFamily)

  // console.log('theme ', theme)
  // 
  
  return (
    <>
    { /* typeof params.customFont !== 'undefined' && typeof customFonts[params.customFont] !== 'undefined'
      ? <span dangerouslySetInnerHTML={{ __html: customFonts[params.customFont] }} />
      : null
    */}
    
    <Typography className={classes.headerTitle} style={{paddingRight: 25, fontSize: matchesSm ? params.fontSize || 25 : params.fontSizeXs || 22 }} variant="span" color="primary">
      <Link style={{ 
        // color: packageName === "ultimate" ? "#ffffff" : null ,
        fontFamily: fontFamily
      }} className={classes.headerTitleA} href="/">{text}</Link>
    </Typography> 
    </>
  );
  
} 
