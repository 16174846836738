// Speed Up https://material-ui.com/guides/minimizing-bundle-size/

import React from 'react'; 
import { useRouter } from 'next/router'; 

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { debounce } from 'shared/utils/debounce';  
import useMediaQuery from '@material-ui/core/useMediaQuery';  
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu'; 
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';  
import Button from '@material-ui/core/Button'; 
import ButtonLink from 'components/ButtonLink'; 
import Box from '@material-ui/core/Box';  

import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'; 


import Logo from 'components/Logo';

import Link from 'components/Link'; 



const headerRoot = {
  'default': {
    backgroundColor: 'transparent',
    flexGrow: 1,  
    transition: 'top 0.6s',
  },
  'wedwedgo.com': {
    backgroundColor: 'rgba(255, 255, 255, 0.85)', 
    flexGrow: 1,  
    transition: 'top 0.6s',
  }
} 

const headerTitleA = {
  'default': {
    color: '#3880ff',
    fontWeight: 500,
    textDecoration: 'none',
  },
  'wedwedgo.com': {
    color: '#3880ff',
    fontWeight: 500,
    textDecoration: 'none',
  }
} 

const logo = {
  'default': { 
  },
  'allweddings.org': {
    display: 'none',
  },
  'weddingteam.org': {
    display: 'none',
  },
  'elopifi.com': {
    display: 'none',
  }
} 

const menuWrap = {
  'default': {
    flex: 1, 
    textAlign: 'right', 
  },
  'allweddings.org': {
    flex: 1, 
    textAlign: 'center'
  },
  'weddingteam.org': {
    flex: 1, 
    textAlign: 'center'
  }
} 

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  mobileMenuWrap: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  mobileMenuButton: {
    paddingRight: 0,
    color: '#3880ff'
  },
  mobileMenuListItem: {
    textAlign: 'center'
  },
  mobileMenuListItemText: {
    width: '250px'
  },
  manuLink: {
    textTransform: 'capitalize'
  },
  headerRoot: headerRoot[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? headerRoot[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : headerRoot['default'], 
  headerRootFixed: {
    transition: 'top 0.6s'
  },
  headerTitleA: headerTitleA[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? headerTitleA[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : headerTitleA['default'], 
  logo: logo[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? logo[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : logo['default'],
  menuWrap: menuWrap[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? menuWrap[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : menuWrap['default'], 
}); 

export default function Header({className, ...props}) {
  // console.log('props ', props) 
  const router = useRouter();
  const { name, packageName, destination, firstName } = router.query;
  const classes = useStyles();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.only('xs'));  
  const matchesSm = useMediaQuery(theme.breakpoints.only('sm'));   
  const matchesMd = useMediaQuery(theme.breakpoints.up('md')); 

  const [prevScrollPos, setPrevScrollPos] = React.useState(0); 
  const [visible, setVisible] = React.useState(true);  
  const [headerPosition, setHeaderPosition] = React.useState('static'); 
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });   

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleScroll = debounce(() => {
    var limit = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
                   document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
    if (window.scrollY !== 0 && headerPosition !== 'fixed') { 
      setHeaderPosition('fixed')
    } else if (window.scrollY === 0) { 
      setHeaderPosition('static')
    }
    const currentScrollPos = window.pageYOffset; 
    // console.log('currentScrollPos ', currentScrollPos, limit)
    setVisible((
      (prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos) || currentScrollPos < 50) 
      && currentScrollPos + window.innerHeight < limit - 100  || limit <= 800 ); 
    setPrevScrollPos(currentScrollPos);
  }, 10); 

  const handlePageScroll = () => { 
    if (window.scrollY !== 0 && headerPosition !== 'fixed') { 
      setHeaderPosition('fixed')
    } else if (window.scrollY === 0) { 
      setHeaderPosition('static')
    }
  }; 

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem className={classes.mobileMenuListItem} component={ButtonLink} href="/" button> 
          <ListItemText className={classes.mobileMenuListItemText} primary={'Home'} />
        </ListItem>
        <ListItem className={classes.mobileMenuListItem} component={ButtonLink} href="/about" button> 
          <ListItemText className={classes.mobileMenuListItemText} primary={'About'} />
        </ListItem>  
        <ListItem className={classes.mobileMenuListItem} component={ButtonLink} href="/faq" button> 
          <ListItemText className={classes.mobileMenuListItemText} primary={'FAQ'} />
        </ListItem> 
        {!['elopifi.com'].includes(process.env["NEXT_PUBLIC_HOSTNAME"]) ? <>
        <ListItem className={classes.mobileMenuListItem} component={ButtonLink} href="/reviews" button> 
          <ListItemText className={classes.mobileMenuListItemText} primary={'Reviews'} />
        </ListItem>
        <ListItem className={classes.mobileMenuListItem} component={ButtonLink} href="/photos" button> 
          <ListItemText className={classes.mobileMenuListItemText} primary={'Photos'} />
        </ListItem>  </> : null }   
      </List>  
    </div>
  );

  React.useEffect(function () {   
    window.addEventListener('scroll', handleScroll);  

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);  
    }
  }) 

  let backgroundImage = {
    // ultimate: "linear-gradient(315deg, rgb(170, 202, 239) 0%, black 74%)",
    ultimate: "linear-gradient(-225deg, rgb(235, 240, 255) 0%, rgb(235, 240, 255) 48%, rgb(235, 240, 255) 100%)",
    prestigePlus: "linear-gradient(315deg, #dcffee 0%, #dcffee 74%)",
    premium: "linear-gradient(120deg, #e5e4ff 0%, #e5e4ff 100%)",
    simple: "linear-gradient(-180deg, #fff8ea 0%, #fff8ea 100%)",
  } 

  // console.log('domainParams ', domainParams)
  // console.log('router.pathname ', router.pathname)
 
  return (
    <React.Fragment> 
      <div className={'headerRootWrap' + ((className) ? ` ${className}` : null)}>
        <AppBar elevation={0} style={{top: !matchesMd ? visible ? '0' : '-70px' : null,
            backgroundImage: packageName && backgroundImage[packageName] ? backgroundImage[packageName] : null, 
          }} className={headerPosition === 'fixed' ? classes.headerRootFixed : classes.headerRoot} color="inherit" position="fixed">
          <Toolbar style={matchesXs ? {minHeight: 48} : {}}>  
            { /* <Typography style={{flex: 0, paddingRight: 25, fontSize: matchesXs ? '22px' : '25px'}} variant="span" color="primary" className={'headerTitle'}>
              <Link style={{ 
                // color: packageName === "ultimate" ? "#ffffff" : null 
              }} className={classes.headerTitleA} href="/">{process.env["NEXT_PUBLIC_COMPANY_NAME"].toLowerCase()}</Link>
            </Typography>  
            */}
           
           <div className={classes.logo}><Logo /></div>
            
            <Box
              className={classes.menuWrap}
              style={{visibility: ['/get-started/availability', '/contract/sign/[pid]'].includes(router.pathname) && (matchesSm || matchesMd) ? 'hidden' : null}}
              component="div"
              sx={{
                display: { xs: 'none', sm: 'block' }
              }}> 
              <Button className={classes.manuLink} component={ButtonLink} href="/" color="inherit">Home </Button>     
              <Button className={classes.manuLink} component={ButtonLink} href="/about" color="inherit">About </Button> 
              <Button className={classes.manuLink} component={ButtonLink} href="/faq" color="inherit">FAQ </Button> 
              {!['elopifi.com'].includes(process.env["NEXT_PUBLIC_HOSTNAME"]) ? <>
              <Button className={classes.manuLink} component={ButtonLink} href="/reviews" color="inherit">Reviews </Button>    
              <Button className={classes.manuLink} component={ButtonLink} href="/photos" color="inherit">Photos </Button>  
              </> : null }         
            </Box>
            <Box
              style={{display: 'none'}}
              component="div"
              sx={{
                display: { xs: 'none', sm: 'block' }
              }}> 
              <ButtonLink href="/get-started" variant="contained" color="primary">Get Started</ButtonLink>
            </Box>  
            <Box className={classes.mobileMenuWrap}
              component="div"
              sx={{
                display: { xs: 'flex', sm: 'none' }
              }}> 
              <IconButton style={{
                color: packageName === "ultimate" ? "#222" : null
              }}
              onClick={toggleDrawer('top', true)} edge="start" className={classes.mobileMenuButton} color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton> 
            </Box> 
          </Toolbar>
        </AppBar>
        <Drawer anchor={'top'} open={state['top']} onClose={toggleDrawer('top', false)}>
          {list('top')}
        </Drawer>
      </div>
    </React.Fragment>
  );
}
